export const translations = {
  privacyPolicy: {
    text: {
      ru: "Политика&nbsp;конфиденциальности",
      en: "Privacy&nbsp;Policy",
      kz: "Құпиялылық&nbsp;саясаты",
    },
    link: {
      ru: "/files/politika-konfidentsialnosti.pdf",
      en: "/files/politika-konfidentsialnosti.pdf",
      kz: "/files/politika-konfidentsialnosti-kz.pdf",
    },
  },
  personalDataPolicy: {
    text: {
      ru: "Политика&nbsp;в&nbsp;отношении персональных&nbsp;данных",
      en: "Personal&nbsp;Data&nbsp;Policy",
      kz: "Дербес&nbsp;деректерге&nbsp;қатысты&nbsp;саясат",
    },
    link: {
      ru: "/files/politiki-v-otnoshenii-pdn.pdf",
      en: "/files/politiki-v-otnoshenii-pdn-en.pdf",
      kz: "/files/politiki-v-otnoshenii-pdn-kz.pdf",
    },
  },
  disclaimer: {
    text: {
      ru: "Дисклеймер",
      en: "Disclaimer",
      kz: "Дисклеймер",
    },
    link: {
      ru: "/files/disclaimer.pdf",
      en: "/files/disclaimer-en.pdf",
      kz: "/files/disclaimer-kz.pdf",
    },
  },
  fund: {
    text: {
      ru: "Фонд",
      en: "",
      kz: "",
    },
    link: {
      ru: "/fund/",
      en: "",
      kz: "",
    },
  },
  cookieBannerText: {
    text: {
      ru: "Для повышения удобства работы с сайтом ООО МФК «Мани Мен» использует файлы cookie. В cookie содержатся данные о прошлых посещениях сайта. Если вы не хотите, чтобы эти данные обрабатывались, отключите cookie в настройках браузера. <br>На информационном ресурсе применяются рекомендательные технологии.",
      en: "",
      kz: "",
    },
  },
};
