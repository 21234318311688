import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { SETTINGS } from "../../settings";

export const HelmetBlock = ({ data }) => {
  const { wordpress_id, yoast, title } = data;

  const [canonical, setCanonical] = useState(null);

  const isNotFound = wordpress_id === null;
  const isFundPage = SETTINGS.FUND_PAGE === wordpress_id;
  const helmetTitle = yoast.title ? yoast.title : title + " | IDF Eurasia";
  const helmetDesc = yoast.metadesc;

  useEffect(() => {
    setCanonical(window.location.href);
  }, []);

  return (
    <Helmet>
      <title>{helmetTitle}</title>
      {helmetDesc && <meta name="description" content={helmetDesc} />}
      {(isNotFound || isFundPage) ? <meta name="robots" content="noindex,nofollow" /> : null}

      <link rel="canonical" href={canonical} />

      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=PT+Sans+Caption:wght@400;700&display=swap"
        rel="stylesheet"
      />

      <meta property="og:title" content={helmetTitle} />
      {helmetDesc && <meta property="og:description" content={helmetDesc} />}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonical} />
      <meta property="og:site_name" content="IDF Eurasia" />

      <meta name="twitter:title" content={helmetTitle} />
      {helmetDesc && (
        <meta property="twitter:description" content={helmetDesc} />
      )}
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
};
