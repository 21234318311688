export const SETTINGS = {
  URL_MASTER: "https://qa-delivery-idfinance-eurasia-ru-master.moneyman.ru/wp",
  // URL_MASTER: "http://idfeurasia.local/wp",
  URL_LOCAL: "http://idfeurasia.local/wp",
  URL_DEVELOP: "http://localhost:8000/wp",
  PAGE_MAIN: [9, 393, 893],
  FUND_PAGE: 8086,
  LANGS: [
    {
      langCode: "ru",
      langName: { ru: "Русский", en: "Русский", kz: "Русский" },
    },
    {
      langCode: "en",
      langName: { ru: "English", en: "English", kz: "English" },
    },
    // {
    //   langCode: "kz",
    //   langName: { ru: "Kazakh", en: "Kazakh", kz: "Kazakh" },
    // },
  ],
};

export const COLORS = {
  text: "#040404",
  text_accent: "#0083E5",
  text_hover: "#0062AC",
  text_active: "#104F7E",
  text_inverted: "#FFFFFF",
  text_secondary: "#898989",
  bg_light: "#F6F8FA",
  bg_dark: "#2D3B4D",
  lines: "#B6BEC0",
};

export const BREAKPOINTS = {
  mobile_xs: 360,
  mobile_s: 375,
  mobile: 576,
  tablet: 768,
  desktop: 1024,
  desktop_lg: 1280,
};

export const LAYOUT = {
  gap: 20,
  gap_tablet: 40,
  gap_desktop: 35,
};

export const TRANSLATIONS = {
  countries: {
    ru: {
      // key for Russia
      ru: "Россия",
      en: "Russia",
      kz: "Ресей",
    },
    kz: {
      // key for Kazakhstan
      ru: "Казахстан",
      en: "Kazakhstan",
      kz: "Қазақстан",
    },
  },
  buttons: {
    burger: {
      ru: "Меню",
      en: "Menu",
      kz: "Мәзір",
    },
    more: {
      ru: "Подробнее",
      en: "More",
      kz: "Көбірек",
    },
  },
  calc: {
    legend_mln: {
      ru: "млн.",
      en: "million",
      kz: "млн.",
    },
    legend_th: {
      ru: "тыс.",
      en: "thousand",
      kz: "мың",
    },
  },
};

export const API = {
  GET_IP: "/rest/getIP.php",
  SEND_CONTACT_PAGE_FORM: "/rest/sendContactsPageForm.php",
  SEND_SUBSCRIBE_FORM: "/rest/sendSubscribeForm.php",
  SEND_SIDEBAR_FORM: "/rest/sendSidebarForm.php",
  SEND_REQUEST: "/rest/invest.php",
  SEND_MANAGER_REQUEST: "/rest/manager.php",
  SEND_SECOND_SHORT_REQUEST: "/rest/sendSecondShortRequest.php",
  SEND_REQUEST_VACANCY: "/rest/vacancy.php",
  SEND_SHORT_FORM: "/rest/sendShortForm.php",
  SEND_CALLBACK: "/rest/callback.php",
  ACTIVATE_SUBSCRIBER: "/rest/subscriber.php",
  GET_USER_DATA: "/rest/getUserData.php",
  HEADERS: {
    POST: {
      method: "POST",
    },
    GET: {
      method: "GET",
    },
  },
};

export const FORM = {
  FORM_INCORRECT: {
    ru: "Проверьте правильность заполненных данных.",
    en: "Проверьте правильность заполненных данных.",
    kz: "Проверьте правильность заполненных данных.",
  },
  FORM_SUCCESS: {
    ru: "Спасибо! Ваша заявка принята.",
    en: "Thanks! Your request is accepted.",
    kz: "Рақмет! Сіздің өтініміңіз қабылданды.",
  },
  FORM_ERROR: {
    ru: "Возникла ошибка, попробуйте позже.",
    en: "Возникла ошибка, попробуйте позже.",
    kz: "Возникла ошибка, попробуйте позже.",
  },
};

export const WP_URL =
  process.env.NODE_ENV === "development"
    ? "http://idfeurasia.local"
    : "https://qa-delivery-idfinance-eurasia-ru-master.moneyman.ru";

export const WP_AJAX = {
  WP_AJAX_URL: WP_URL + "/wp-admin/admin-ajax.php",
};
