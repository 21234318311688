import { createGlobalStyle } from 'styled-components';

import { COLORS, BREAKPOINTS, LAYOUT } from '../../../settings';
import { hexToRgb } from '../../../helpers/base';

import icoButtonArrowLight from '../../../images/button_arrow_light.svg';

export const GlobalStyle = createGlobalStyle`
  :root {
    --content-width: 1366px;
  }

  body {
    font-family: "PT Sans Caption", sans-serif;
    color: ${COLORS.text};
    font-size: 15px;
    line-height: 25px;
    font-weight: 400;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
      font-size: 16px;
      line-height: 25px;
    }

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
      font-size: 20px;
      line-height: 35px;
    }

    &.modal-open {
      overflow: hidden;
    }
  }

  a {
    transition: color .15s ease-out;

    &:focus {
      outline-color: transparent;
    }

    &:not(:any-link) {
      text-decoration: none;
    }
  }


  button {
    &:focus {
      outline-color: transparent;
    }
  }

  .text-big {
    font-size: 20px;
    line-height: 30px;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
      font-size: 25px;
      line-height: 35px;
    }

    @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
      font-size: 30px;
      line-height: 45px;
    }
  }

  .text-bold {
    font-weight: 700;
  }

  .h1 {
    margin: 0;
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
      font-size: 35px;
      line-height: 45px;
    }

    @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
      font-size: 50px;
      line-height: 65px;
    }
  }

  .h2 {
    margin: 0;
    font-size: 25px;
    line-height: 30px;
    font-weight: 700;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
      font-size: 25px;
      line-height: 35px;
    }

    @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
      font-size: 30px;
      line-height: 40px;
    }
  }

  .h3 {
    margin: 0;
    font-size: 20px;
    line-height: 25px;
    font-weight: 700;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
      font-size: 20px;
      line-height: 25px;
    }

    @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
      font-size: 25px;
      line-height: 35px;
    }

    &__regular {
      font-weight: 400;
    }
  }

  .number {
    color: ${COLORS.text_accent};
    font-size: 25px;
    line-height: 30px;
    font-weight: 700;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
      font-size: 30px;
      line-height: 30px;
    }

    @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
      font-size: 30px;
      line-height: 30px;
    }

    &__big {
      font-size: 30px;
      line-height: 40px;

      @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        font-size: 30px;
        line-height: 40px;
      }

      @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
        font-size: 40px;
        line-height: 40px
      }
    }

    &__super {
      font-size: 35px;
      line-height: 45px;

      @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        font-size: 40px;
        line-height: 50px;
      }

      @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
        font-size: 50px;
        line-height: 65px;
      }
    }
  }

  .block-container {
    max-width: 1366px;
    margin: 0 auto;
    padding: 40px ${LAYOUT.gap + 'px'};

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
      padding: 60px ${LAYOUT.gap_tablet / 2 + 'px'};
    }

    @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
      padding: 60px ${LAYOUT.gap_desktop * 2 + 'px'};
    }
  }

  .element-container {
    max-width: 1366px;
    margin: 0 auto;
    padding: 0 ${LAYOUT.gap + 'px'};

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
      padding: 0 ${LAYOUT.gap_tablet / 2 + 'px'};
    }

    @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
      padding: 0 ${LAYOUT.gap_desktop * 2 + 'px'};
    }
  }

  .menu,
  .menu_submenu {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .side-panel {
    @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
      width: calc(75% - ${LAYOUT.gap_desktop / 2 + 'px'});
      margin: 0 0 0 auto;
    }
  }

  .button {
    display: inline-block;
    background: ${COLORS.text_accent};
    padding: 14px 24px;
    border: 1px solid ${COLORS.text_accent};
    border-radius: 25px;
    color: ${COLORS.text_inverted};
    font-size: 15px;
    line-height: 20px;
    text-align: center;

    @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
      border-radius: calc(55px / 2);
      font-size: 16px;
      line-height: 25px;
    }

    span {
      display: inline-block;
      transform: translateY(-1px);
    }
  }

  .button__secondary {
    background: transparent;
    color: ${COLORS.text_accent};

    &:hover,
    &:focus {
      color: #0062AC;
    }
  }

  .button__light {
    color: ${COLORS.text_inverted};
    border-color: ${COLORS.text_inverted};

    transition: border .15s ease-out;

    &:hover,
    &:focus {
      color: ${COLORS.text_inverted};
      border-color: rgba(${hexToRgb(COLORS.text_inverted).toString()}, .5);
    }

    &::after {
      content: "";
      vertical-align: middle;

      display: inline-block;
      background: url(${icoButtonArrowLight}) center/contain no-repeat;
      width: 20px;
      height: 20px;
      margin: 0 0 0 5px;
    }
  }

  .button.button__empty {
    border-color: transparent;

    &:hover,
    &:focus {
      color: #FFFFFF;
      border-color: transparent;
    }
  }

  .button__with-arrow {
    .icon__arrow {
      margin: 0 0 0 5px;

      path {
        stroke: currentColor;
      }
    }
  }

  .visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
`;
